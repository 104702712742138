<template>
  <div class="test">
    <!-- <button @click="getinfo()">进入首页</button> -->
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {};
  },
  beforeMount() {
    this.$router.onReady(() => {
      if (
        this.$route.query.token != undefined &&
        this.$route.query.token != null
      ) {
        this.setCookie("token", this.$route.query.token, 1);

        console.log(this.getCookie("token"));
      }
    });
  },
  mounted() {
    util.post("/teacherInfo", {}, (res) => {
      console.log(res);
      if (res.code == 1) {
        localStorage.setItem("wechat", JSON.stringify(res.msg.wechat_info));
        if (res.msg.phone == null) {
          this.$router.push("/login/wxlink");
        } else {
          this.$router.push("/main/starts");
        }
      }
    });
  },
  methods: {
    // getinfo() {
    //   util.post("/teacherInfo", {}, (res) => {
    //     console.log(res);
    //     if (res.code == 1) {
    //       localStorage.setItem("wechat", JSON.stringify(res.msg.wechat_info));
    //       if (res.msg.phone == null) {
    //         this.$router.push("/login/wxlink");
    //       } else {
    //         this.$router.push("/main/starts");
    //       }
    //     }
    //   });
    // },
    setCookie(name, value, type) {
      var Days = 1;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      var x;
      if (type) {
        x = new Date(
          new Date(new Date().toLocaleDateString()).getTime() +
            24 * 60 * 60 * 1000 -
            1
        );
      } else {
        x = exp.toGMTString();
      }
      document.cookie =
        name + "=" + escape(value) + ";expires=" + x + ";path=/";
    },
    getCookie(c_name) {
      //判断document.cookie对象里面是否存有cookie
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        //如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) c_end = document.cookie.length;
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return "";
    },
  },
};
</script>